import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';
import SEO from '../../../components/seo';

export default function RegisterDubaiIndustrialCity(props) {
  return (
    <Layout>
      <SEO lang='en' title='#GMIS2022 - Embassies outside the UAE' />
      <RegistrationFrom source='Embassies Outside UAE' page='embassies' imageName='moiat-unido' imageAlt='embassies' />
    </Layout>
  );
}
